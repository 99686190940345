//@import 'fonfont-awesome/scss/font-awesome.scss';
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import "mixins";

$main-color: #eee;

body {
  background-color: $main-color;
  font-family: "Roboto", sans-serif;
  color: darkblue;
}

.text-footer {
  margin: 24px 50px;
}

@for $i from 1 through 5 {
  li:nth-child(3n + #{$i}) {
    background-color: lighten($main-color, 15% + $i * 5%);
  }
}

.logo {
  width: 136px;
  cursor: pointer;
}
.present-li {
  border-bottom: solid 1px;
  border-color: #553333;
  opacity: 0.75;
}
.homeContent {
  padding-top: 8px;
  width: 320px;
  margin-left: auto;
  margin-right: auto;
}
#nick {
  margin-left: 8px;
  height: 32px;
  border-radius: 5px;
  border-width: 1px;
  padding-left: 5px;
}
#buttonRemember {
  display: block;
  width: 240px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
}
#buttonForget {
  display: block;
  width: 240px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
}
#startNow {
  display: block;
  width: 240px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
}
.exerciseContent {
  padding-top: 8px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.operation {
  margin: 5px;
  display: inline-block;
}

.scoresContent {
  padding-top: 8px;
  width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.score-item:hover {
  cursor: pointer;
}
.fa {
  margin-left: 8px;
}
p.answear {
  margin: 0px 8px;
}

span.text-big {
  font-size: 24px;
}

.table-scores {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

#rachmistrz {
  display: none;
}
